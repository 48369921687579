import React from 'react';
import Markdown from 'markdown-to-jsx';
import Layout from '../components/layout';
import SEO from '../components/seo';

const markdown = `
# Mentions légales

## Ethibox

Le site [ethibox.fr](https://ethibox.fr) est édité par :

[Joachim C.](https://twitter.com/_johackim)   
26 Rue de la Commanderie   
54000 Nancy, FRANCE

Siret: 839 304 151 00021

[contact@ethibox.fr](mailto:contact+site@ethibox.fr)

## Hébergeur

L'infrastructure de [ethibox.fr](https://ethibox.fr) est hébergé chez :

Ikoula   
175-177 rue d'Aguesseau   
92100 Boulogne Billancourt   
France   

## Conditions générales d'utilisation

Vous pouvez consulter les conditions générales d'utilisation ici : [Conditions Générales d'Utilisation](/cgu)
`;

const Legals = () => {
    return (
        <Layout>
            <SEO title="Mentions légales" />
            <section className="pt-28 max-w-7xl mx-auto px-4 lg:px-8">
                <Markdown
                    options={{
                        overrides: {
                            h1: { props: { className: 'font-bold text-3xl' } },
                            h2: { props: { className: 'font-bold text-2xl mt-4' } },
                            p: { props: { className: 'my-2 text-base leading-6 text-gray-500' } },
                        },
                    }}
                >
                    {markdown}
                </Markdown>
            </section>
        </Layout>
    );
};

export default Legals;
